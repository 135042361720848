import { Component, inject, Input } from '@angular/core';
import { OrderDetailDTO } from '@soctrip/angular-order-service';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-request-status-detail',
  templateUrl: './request-status-detail.component.html',
  styleUrls: ['./request-status-detail.component.scss']
})
export class RequestStatusDetailComponent {
  @Input() order: OrderDetailDTO;
  translator = inject(CustomTranslateService);
  constructor(private fileService:FileService){

  }
  orderPrefix = 'section-order-management.';
  orderDescription = `${this.orderPrefix}text-description.`;
  statusPrefix = 'section-status.';
  getCreatedDay(status: any) {
    return status?.created_at;
  }

  getImage(item: any) {
    return this.fileService.getImgWebp(item?.id);
  }

  getMessage(message: string | undefined): string {
    const validatedMessage  = message || '';
    const isOrderMessage = /common\.compete-order|comete-/.test(validatedMessage );

    if (isOrderMessage) {
      return this.translator.sentenceCase(
        `section-order-management.complete-order`,
      );
    }

    return validatedMessage ;
  }
}

import { Component, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Toast } from './core/utils/toast.util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService, Toast],
})
export class AppComponent implements OnInit {
  smallScreen = 1439;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;
  environment = environment;
  sanitizer = inject(DomSanitizer);
  iframeUrl: SafeResourceUrl;

  constructor(private primeNGConfig: PrimeNGConfig) {}

  ngOnInit(): void {
    this.primeNGConfig.setTranslation({
      firstDayOfWeek: 1,
    });

    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.ADMIN_HUB_URL}/app-connect`,
    );
  }

  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  handleOnLoad = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: 'connect' }, '*');
    }
  };
}

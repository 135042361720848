import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  OrderControllerService,
  OrderDetailDTO,
} from '@soctrip/angular-order-service';
import { Subscription } from 'rxjs';
import { IOrderProduct } from 'src/app/core/models/interfaces/shop';
import { ShopControllerService } from '@soctrip/angular-shop-service';
import { ShippingControllerService } from '@soctrip/angular-shipment-service';
import { Carrier } from 'src/app/core/models/interfaces/shipment';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit {
  
  readonly translatePrefix = 'section-order-management.';

  isRefund = false;
  isLoading: boolean = false;
  isFetched: boolean = false;
  orderId: string;
  orderDetail: OrderDetailDTO;
  language: string = localStorage.getItem('lang') || 'en';
  languageSubscription: Subscription;
  estimatedArrivalDelivery: string = '';
  trackingTypeList: Carrier[] = [];
  shopId: string;
  isReShipMode: boolean = false
  isClearTrackingMethod : boolean = false;

  constructor(
    private orderService: OrderControllerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private shopControllerService: ShopControllerService,
    private shippingControllerService: ShippingControllerService,
    private viewportScroller: ViewportScroller,
  ) {}

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.orderId = this.activatedRoute.snapshot.params['id'];
    this.isRefund = this.activatedRoute.snapshot.params['type'] == 'refund';
    if (this.orderId) {
      this._initOrdersManagementIdGet();
      this.getEstimatedArrivalDelivery(this.orderId);
    }
    this.refetchOrdersManagementIdGet();

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['mode'] === 'reship') {
        this.isReShipMode = true;
      } else {
        this.isReShipMode = false;
      }
    });
  }

  get getAllProducts(): IOrderProduct[] {
    if (this.orderDetail?.shops) {
      const allProducts = this.orderDetail?.shops?.flatMap(
        (shop) => shop?.products || [],
      ) as IOrderProduct[];
      return allProducts;
    }
    return [];
  }

  get getTickets() {
    const tickets = this.getAllProducts?.flatMap(
      (product) => product?.ticketQrCodes || [],
    );
    return tickets;
  }

  refetchOrdersManagementIdGet() {
    this.languageSubscription = this.translateService.onLangChange.subscribe(
      (res) => {
        this.language = res.lang;
        this._initOrdersManagementIdGet();
        this.getEstimatedArrivalDelivery(this.orderId);
      },
    );
  }

  _initOrdersManagementIdGet() {
    this.isLoading = true;
    this.orderService
      .ordersManagementIdGet(this.orderId, this.language)
      .subscribe({
        next: (res) => {
          this.orderDetail = res?.data as OrderDetailDTO;
          this.shopId = this.orderDetail?.shops?.[0]?.shop_id ?? '';
          this.onGetTrackingList(this.shopId);
        },
        error: () => {
          this.isLoading = false;
          this.isFetched = true;
        },
        complete: () => {
          this.isLoading = false;
          this.isFetched = true;
        },
      });
  }

  onGetTrackingList(shop_id: string) {
    this.shopControllerService.shopsIdInfoGet(shop_id).subscribe({
      next: (res) => {
        const products = this.orderDetail?.shops?.[0]?.products?.map((product) => {
            return {
              stock_id: product.stock_id ?? '',
            quantity: product.quantity
          }
        });
        const body = {
          address_from: {
            name: res.data.owner_name,
            phone: res.data.phone,
            street: res.data.street,
            ward: res.data.ward?.id,
            district: res.data.district.id,
            city: res.data.province.id,
          },
          address_to: {
            name: this.orderDetail.address?.user_full_name || '',
            phone: this.orderDetail.address?.user_phone_number || '',
            street: this.orderDetail.address?.address || '',
            ward: this.orderDetail.address?.ward_id || '',
            district: this.orderDetail.address?.district_id || '',
            city: this.orderDetail.address?.city_id || '',
            country: this.orderDetail.address?.country_id || '',
          },
          products: products,
        };
        this.shippingControllerService.shippingFeesPost(body, 'en').subscribe({
          next: (res) => {
            this.trackingTypeList = res.data as Carrier[]
          },
        });
      },
    });
  }

  onBackOrder() {
    this.router.navigate(['order'], {
      queryParamsHandling: 'merge',
    });
  }

  onUpdateOrderInformation() {
    this.isClearTrackingMethod = true
    this._initOrdersManagementIdGet();
  }

  getEstimatedArrivalDelivery(id: string = this.orderId) {
    this.orderService
      .ordersIdEstimatedArrivalTimeGet(this.orderId, this.language)
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.estimatedArrivalDelivery = response.data;
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }
}

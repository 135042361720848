import { Pipe, PipeTransform } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'redirectUrl',
})
export class RedirectUrlPipe implements PipeTransform {
  transform(shopId?: string): string {
    const token = localStorage.getItem('accessToken');
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');

    const adminToken = CryptoJS.AES.encrypt(
      token + window.crypto.randomUUID().slice(0, 5),
      'token',
    ).toString();

    if (token && auth.id && shopId) {
      return `${
        environment.ECOMMERCE_MANAGEMENT_DOMAIN_URL
      }/shops/${shopId}/analytics/dashboard?adminToken=${encodeURIComponent(
        adminToken,
      )}&userId=${auth?.id}&permission=admin`;
    }

    return '';
  }
}

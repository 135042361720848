import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { headerEnum } from 'src/app/core/enum/header.enum';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isSmallScreen: boolean = false;
  @Input() user: any;
  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter();
  environment = environment;
  translator = inject(CustomTranslateService);
  sanitizer = inject(DomSanitizer);
  headerEnum = headerEnum;
  appLogo = `../assets/imgs/logo/${environment.APP_LOGO}.svg`;

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  changeLanguage(language: string) {
    this.translator.setLanguage(language);
  }

  handleSignOut = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: 'disconnect',
        data: {
          token: localStorage.getItem('accessToken'),
          user_id: localStorage.getItem('userId'),
        },
      };
      frame.contentWindow.postMessage(messageData, {
        targetOrigin: environment.ADMIN_HUB_URL,
      });
    }
    localStorage.clear();
    window.location.href = `${environment.ADMIN_HUB_URL}/login`;
  };
}

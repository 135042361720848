import { Component, Input } from '@angular/core';
import { OrderDetailDTO } from '@soctrip/angular-order-service';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
})
export class PaymentInformationComponent {
  @Input() order: OrderDetailDTO;
  readonly SoctripIcons = SoctripIcons;
  orderPrefix = 'section-order-management.';
  constructor(private fileService: FileService) {}

  getCurrencyCode(): string {
    return 'USD';
  }
  getStyleVoucher(voucherType: string) {
    let result = 'bg-orange-dark-50 text-orange-700';
    if (voucherType === 'FREE_SHIP') result = 'bg-green-50 text-green-600';
    return result;
  }
}

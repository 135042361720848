import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ShipmentBody } from '../models/interfaces/shipment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService {
  private baseUrl = `${environment.BE_URL}order/shipments`;
  constructor(private httpClient: HttpClient) {}

  shipmentsReShipId(id: string, body: ShipmentBody) {
    return this.httpClient.post(`${this.baseUrl}/re-ship/${id}`, body);
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { OrderDetailDTO } from '@soctrip/angular-order-service';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { AddressDirectoryControllerService } from '@soctrip/angular-shipment-service';
import { forkJoin } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import {
  Provinces,
  District,
  Country,
  Ward,
  CountryCode,
} from 'src/app/core/models/interfaces/shipment';
import { ShipmentControllerService } from '@soctrip/angular-order-service';
import { MessageService } from 'primeng/api';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-delivery-information',
  templateUrl: './delivery-information.component.html',
  styleUrls: ['./delivery-information.component.scss'],
})
export class DeliveryInformationComponent implements OnChanges {
  @Input() order: OrderDetailDTO;
  @Input() isReshipMode: boolean = false;

  @Output() onUpdateDeliveryInf = new EventEmitter();

  constructor(
    private addressDirectoryControllerService: AddressDirectoryControllerService,
    private fb: FormBuilder,
    private shipmentControllerService: ShipmentControllerService,
    private messageService: MessageService,
    private translator: CustomTranslateService,
  ) {}

  readonly SoctripIcons = SoctripIcons;
  orderPrefix = 'section-order-management.';
  textDescription = 'text-description.';
  actionPrefix = 'section-action.';
  visible: boolean = false;
  countryId: string = '';
  districtId = '';
  cityId: string = '';
  wardId: string = '';
  deliveryInfoForm = this.fb.group({
    fullName: [''],
    phoneNumber: [''],
    addressLine1: [''],
    addressLine2: [''],
    ward: [''],
    district: [''],
    province: [''],
    country: [''],
  });
  countries: Country[] = [];
  provinces: Provinces[] = [];
  districts: District[] = [];
  wards: Ward[] = [];
  isLoading: boolean = false;
  countryCode: CountryCode[] = [{ code: 'VN' }, { code: 'US' }];

  getAddress(order: OrderDetailDTO) {
    let addressArray: any[] = [];
    if (order?.address) {
      const { address, ward, district, city, country } = order?.address;
      addressArray = [address, ward, district, city, country];
    }
    return addressArray.filter((prop) => !!prop).join(', ');
  }

  onEditDeliveryInformation() {
    this.visible = true;
  }

  ngOnChanges() {
    this.countryId = this.order?.address?.country_id || '';
    this.cityId = this.order?.address?.city_id || '';
    this.districtId = this.order?.address?.district_id || '';
    this.wardId = this.order?.address?.ward_id || '';
    forkJoin([
      this.addressDirectoryControllerService.addressCountriesGet(),
      this.addressDirectoryControllerService.addressProvincesGet(
        this.countryId,
      ),
      this.addressDirectoryControllerService.addressDistrictsGet(this.cityId),
      this.addressDirectoryControllerService.addressWardsGet(this.districtId),
    ]).subscribe({
      next: (results) => {
        const [countryList, provinceList, districtList, wardList] = results;
        this.countries = countryList.data;
        this.provinces = provinceList.data;
        this.districts = districtList.data;
        this.wards = wardList.data;
        this.deliveryInfoForm.patchValue({
          fullName: this.order?.address?.user_full_name,
          phoneNumber: this.order?.address?.user_phone_number,
          addressLine1: this.order?.address?.address,
          addressLine2: '',
          ward: this.order?.address?.ward_id,
          district: this.order?.address?.district_id,
          province: this.order?.address?.city_id,
          country: this.order?.address?.country_id,
        });
      },
    });
  }

  onUpdateDistrict(event: DropdownChangeEvent) {
    this.addressDirectoryControllerService
      .addressWardsGet(event.value)
      .subscribe({
        next: (res) => {
          this.wards = res.data;
        },
      });
  }

  onUpdateProvince(event: DropdownChangeEvent) {
    this.addressDirectoryControllerService
      .addressDistrictsGet(event.value)
      .subscribe({
        next: (res) => {
          this.districts = res.data;
        },
      });
  }

  onUpdateCountry(event: DropdownChangeEvent) {
    this.provinces = [];
    this.districts = [];
    this.wards = [];
    this.addressDirectoryControllerService
      .addressProvincesGet(event.value)
      .subscribe({
        next: (res) => {
          this.provinces = res.data;
        },
      });
  }

  onHandleSubmit(id: any) {
    this.isLoading = true;
    const body = {
      name: this.deliveryInfoForm.get('fullName')?.value || '',
      phone: this.deliveryInfoForm.get('phoneNumber')?.value || '',
      street: this.deliveryInfoForm.get('addressLine1')?.value || '',
      ward: this.deliveryInfoForm.get('ward')?.value || '',
      district: this.deliveryInfoForm.get('district')?.value || '',
      city: this.deliveryInfoForm.get('province')?.value || '',
      country: this.deliveryInfoForm.get('country')?.value || '',
    };
    this.shipmentControllerService.shipmentsIdDeliveryPut(body, id).subscribe({
      next: (res) => {
        this.messageService.add({
          severity: 'success',
          detail: this.translator.sentenceCase(
            this.orderPrefix + 'update-order-infomation-successfully',
          ),
        });
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translator.sentenceCase(
            this.orderPrefix + 'update-order-information-failed',
          ),
        });
        this.isLoading = false;
      },
      complete: () => {
        this.visible = false;
        this.onUpdateDeliveryInf.emit();
        this.isLoading = false;
      },
    });
  }
}

export enum ModuleAdminEnum {
  SHOP = 'SHOP_MODULE_ADMIN',
  FOOD = 'FOOD_MODULE_ADMIN',
  CAR = 'CAR_MODULE_ADMIN',
  FINANCE = 'FINANCE_MODULE_ADMIN',
}

export interface Permission {
  id: string;
  type_moderator: ModuleAdminEnum;
  role: string;
}

<ng-container
  *ngIf="order?.payment"
  [ngTemplateOutlet]="paymentTemplate"
  [ngTemplateOutletContext]="{ payment: order?.payment }"
></ng-container>

<ng-template #paymentTemplate let-payment="payment">
  <div
    class="flex flex-col gap-5 border border-gray-200 shadow-sm bg-white rounded-md w-full px-6 py-5"
  >
    <span class="text-lg font-semibold text-gray-900">{{
      orderPrefix + "payment-information" | translate | sentenceCase
    }}</span>
    <div class="flex flex-col gap-3">
      <!-- Order total -->
      <div class="flex flex-row justify-between items-center">
        <span class="text-sm text-gray-500 font-medium">{{
          orderPrefix + "order-total" | translate | sentenceCase
        }}</span>
        <span class="text-sm font-semibold text-gray-900">{{
          payment?.order_total | currency: getCurrencyCode()
        }}</span>
      </div>

      <!-- Payment gateway -->
      <div class="flex items-center justify-between gap-2 text-sm">
        <span class="text-gray-500 font-medium">
          {{ orderPrefix + "payment-gateway" | translate | sentenceCase }}
        </span>
        <span class="text-gray-400 font-normal">
          {{ payment?.payment_method?.gateway?.name || "--" }}
        </span>
      </div>

      <!-- Payment method -->
      <div class="flex items-center justify-between gap-2 text-sm">
        <span class="text-gray-500 font-medium">
          {{ orderPrefix + "payment-method" | translate | sentenceCase }}
        </span>
        <span class="text-gray-400 font-normal">
          {{ payment?.payment_method?.name || "--" }}
        </span>
      </div>

      <!-- Voucher -->
      <div class="flex items-center justify-between text-sm min-w-max">
        <span class="text-gray-500 font-medium min-w-fit">
          {{ orderPrefix + "vouchers" | translate | sentenceCase }}
        </span>

        <span class="w-full flex items-center justify-end gap-2"
          ><span
            *ngFor="let voucher of payment?.vouchers"
            class="flex items-center justify-center gap-1 py-1 pr-[0.75rem] pl-[0.625rem] rounded-2xl w-fit"
            [ngClass]="getStyleVoucher(voucher?.type)"
          >
            {{ voucher?.name }}
          </span></span
        >
      </div>

      <!-- Tax -->
      <div class="flex items-center justify-between text-sm min-w-max">
        <span class="text-gray-500 font-medium">
          {{ orderPrefix + "tax" | translate | sentenceCase }}
        </span>
        <span class="text-gray-400 font-normal">
          {{ payment?.tax | currency: getCurrencyCode() }}
        </span>
      </div>

      <!-- Discounts -->
      <div
        *ngIf="payment?.discounts || true"
        class="flex items-center justify-between text-sm min-w-max"
      >
        <span class="text-gray-500 font-medium">
          {{ orderPrefix + "discounts" | translate | sentenceCase }}
        </span>
        <span class="text-gray-400 font-normal">
          {{ -payment?.discounts | currency: getCurrencyCode() }}
        </span>
      </div>

      <!-- delivery -->
      <div class="flex items-center justify-between text-sm min-w-max">
        <span class="text-gray-500 font-medium">
          {{ orderPrefix + "delivery" | translate | sentenceCase }}
        </span>
        <span class="text-gray-400 font-normal">
          {{ payment?.shipment_fee | currency: getCurrencyCode() }}
        </span>
      </div>

      <!-- delivery discount -->
      <div class="flex items-center justify-between text-sm min-w-max">
        <span class="text-gray-500 font-medium">
          {{ orderPrefix + "delivery-discount" | translate | sentenceCase }}
        </span>
        <span class="text-gray-400 font-normal">
          {{ -payment?.shipment_discount | currency: getCurrencyCode() }}
        </span>
      </div>

      <!-- Total payment -->
      <div
        footer
        class="flex items-center justify-between pt-4 border-t border-gray-100"
      >
        <span class="font-semibold">
          {{ orderPrefix + "total-payment" | translate | sentenceCase }}
        </span>
        <span
          *ngIf="payment?.payment_total"
          class="text-lg leading-7 font-bold text-orange-dark-500"
        >
          {{ payment?.payment_total | currency: getCurrencyCode() }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

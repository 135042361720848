import { HttpClient } from '@angular/common/http';
import { Component, Input, SimpleChanges, inject } from '@angular/core';
import {
  OrderControllerService,
  OrderDetailDTO,
  Reason,
  StatusControllerService,
  TabControllerService,
} from '@soctrip/angular-order-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { delay } from 'rxjs';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { StatusCodeEnum } from 'src/app/core/enum/order.enum';
import { ResponseData } from 'src/app/core/models/interfaces/common';
import { Permission } from 'src/app/core/models/interfaces/permission';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { ErrorHandler } from 'src/app/core/utils/error-handler.util';
import { Toast } from 'src/app/core/utils/toast.util';
import { OrderDetailComponent } from 'src/app/features/order/pages/order-detail/order-detail.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
})
export class OrderStatusComponent {
  @Input() order: OrderDetailDTO;
  readonly SoctripIcons = SoctripIcons;

  confirmationService = inject(ConfirmationService);
  messageService = inject(MessageService);
  tabService = inject(TabControllerService);
  orderService = inject(OrderControllerService);
  toast = inject(Toast);
  statusService = inject(StatusControllerService);
  translator = inject(CustomTranslateService);
  orderDetailComponent = inject(OrderDetailComponent);
  orderPrefix = 'section-order-management.';
  orderDescription = `${this.orderPrefix}text-description.`;
  actionPrefix = 'section-action.';
  tabCodePrefix = `${this.orderPrefix}tab-code.`;
  requiredPrefix = 'section-required.';
  statusPrefix = 'section-status.';

  isPending: boolean = false;

  tabList: any[] = [];

  originalTabCode: string;
  currentOrderTabCode: string;
  progressTabList: any[] = [];

  isCancelOrderVisible = false;
  orderCancelReasonList: Reason[] = [];
  selectedReason?: Reason = undefined;
  isConfirming: boolean = false;
  statusCodeEnum = StatusCodeEnum;
  isSupperAdmin: boolean = false;

  constructor(
    private errorHandler: ErrorHandler,
    private httpClient: HttpClient,
  ) {
    this.errorHandler = new ErrorHandler(this.toast);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleDataChange();
  }

  ngOnInit(): void {
    this.getOrderCancelReasonList();
    this.onCheckPermission();
  }

  handleDataChange() {
    this.getDefaultTabCode();
    this.getTabsData();
  }

  getDefaultTabCode() {
    this.currentOrderTabCode = this.order?.status?.status_property
      ?.tab as string;
    this.originalTabCode = this.order?.status?.status_property?.tab as string;
  }

  //Response is not in the format of: (data, success, error)
  getTabsData() {
    if (!this.order?.id) return;
    this.isPending = true;
    this.tabService.tabsOrdersIdGet(this.order.id!).subscribe({
      next: (response: any) => {
        const { data, error, success } = response;
        if (success) {
          this.tabList = data;
          // // this.getMutableList();
          // // this.toNestedTabList();
          this.getStatusProgress();
        } else this.handleError(error);
      },
      error: (error: any) => this.handleError(error),
      complete: () => (this.isPending = false),
    });
  }

  getOrderCancelReasonList() {
    this.isPending = true;
    this.statusService.statusReasonsGet().subscribe({
      next: ({ data, error, success }) => {
        if (success) {
          this.orderCancelReasonList = data as Reason[];
          this.isPending = false;
        } else this.handleError(error);
      },
      error: (error: any) => this.handleError(error),
    });
  }

  getStatusProgress() {
    this.progressTabList = [];
    for (const tab of this.tabList) {
      this.progressTabList.push(tab);
      if (tab?.code === this.currentOrderTabCode) {
        break;
      }
    }
  }

  getTabIconResponsive(code?: string): string {
    switch (code) {
      case this.statusCodeEnum.WAIT_FOR_CONFIRMATION:
        return SoctripIcons.PACKAGE_PLUS;
      case this.statusCodeEnum.WAIT_FOR_SHIPMENT:
        return SoctripIcons.PACKAGE_SEARCH;
      case this.statusCodeEnum.TO_DELIVER:
        return SoctripIcons.TRUCK_01;
      case this.statusCodeEnum.DELIVERED:
        return SoctripIcons.PACKAGE_CHECK;
      case this.statusCodeEnum.COMPLETED:
        return SoctripIcons.CHECK_DONE_01;
      case this.statusCodeEnum.CANCELLED:
        return SoctripIcons.REFRESH_CCW_01;
      case this.statusCodeEnum.REFUND:
        return SoctripIcons.FLIP_BACKWARD;
      default:
        return 'hidden';
    }
  }

  getTabClass(tab: any): string {
    // const active: string = !this.isFlowEnd() ? 'active' : '';
    const active = '';
    // const arrowType: string =
    // tab?.code == this.currentOrderTabCode ||
    // (tab?.length && this.progressTabList.length === this.shownTabs)
    //   ? 'arrow-selected'
    //   : this.progressTabList.includes(tab)
    //   ? 'arrow-progress'
    //   : 'arrow-normal';
    const arrowType: string =
      tab?.code == this.currentOrderTabCode || tab?.length
        ? 'arrow-selected'
        : this.progressTabList.includes(tab)
        ? 'arrow-progress'
        : 'arrow-normal';

    // const tabCode = tab?.code || this.selectedTabList?.code || '';
    const tabCode = tab?.code || '';

    return `${arrowType} ${active} ${tabCode}`;
  }

  isShowTabName(tab: any): boolean {
    if (tab?.code) return tab?.code === this.currentOrderTabCode;
    // return tab?.length && this.progressTabList.length === this.shownTabs;
    return tab?.length;
  }

  isHideIconInSmallScreen(tab: any): boolean {
    return this.isShowTabName(tab);
  }

  openCancelDialog() {
    this.isCancelOrderVisible = true;
  }

  handleError(error: any) {
    this.errorHandler.handle(error);
    this.isPending = false;
    this.isCancelOrderVisible = false;
  }

  getStatusIcon(code: string) {
    const url = 'assets/icons/order-status';
    switch (code) {
      case this.statusCodeEnum.TO_SHIP:
      case this.statusCodeEnum.WAIT_FOR_SHIPMENT:
      case this.statusCodeEnum.TO_DELIVER:
      case this.statusCodeEnum.DELIVERED:
      case this.statusCodeEnum.COMPLETED:
      case this.statusCodeEnum.CANCELLED:
      case this.statusCodeEnum.REFUND:
        return `${url}/${code.toLowerCase()}.svg`;
      default:
        return 'hidden';
    }
  }

  confirmOrder() {
    this.isConfirming = true;
    this.orderService
      .ordersIdStatusPut(this.order.id!, this.statusCodeEnum.CONFIRMED)
      .pipe(delay(1500))
      .subscribe({
        next: ({ data, error, success }: any) => {
          if (success) {
            const title = this.translator.sentenceCase(
              `${this.statusPrefix}success`,
            );
            const message = this.translator.sentenceCase(
              `${this.orderDescription}updated-status`,
            );
            this.toast.success(title, message);

            this.orderDetailComponent._initOrdersManagementIdGet();
          } else this.handleError(error);
        },
        error: (error: any) => {
          console.log('a');

          this.handleError(error);
          this.isConfirming = false;
        },
        complete: () => {
          this.isConfirming = false;
        },
      });
  }

  confirmCancel() {
    if (!this.selectedReason) {
      const title = this.translator.sentenceCase(
        `${this.requiredPrefix}lack-of-information`,
      );
      const message = this.translator.sentenceCase(
        `${this.orderDescription}select-a-reason`,
      );
      this.toast.warn(title, message);
      return;
    }
    this.isCancelOrderVisible = false;
    this.isPending = true;
    const message = `${this.selectedReason.name}`;
    this.orderService
      .ordersIdStatusPut(
        this.order.id!,
        this.statusCodeEnum.CANCELLED_BY_SHOP,
        message,
      )
      .subscribe({
        next: ({ data, error, success }) => {
          if (success) {
            const title = this.translator.sentenceCase(
              `${this.statusPrefix}success`,
            );
            const message = this.translator.sentenceCase(
              `${this.orderDescription}cancelled-order`,
            );
            this.toast.success(title, message);
            this.orderDetailComponent._initOrdersManagementIdGet();
          } else this.handleError(error);
        },
        error: (error: any) => {
          this.handleError(error);
          this.isPending = false;
        },
        complete: () => {
          this.selectedReason = undefined;
          this.isPending = false;
        },
      });
  }

  onCheckPermission() {
    this.httpClient
      .get<ResponseData<Permission[]>>(
        `${environment.BE_URL}user/admin/roles/module`,
      )
      .subscribe({
        next: (res) => {
          const permissions = res.data ?? [];
          this.isSupperAdmin = !!permissions.find(
            (permission) => permission.role === 'SUPER_ADMIN',
          );
        },
      });
  }
}

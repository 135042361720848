<ng-container *ngIf="isFetched && orderDetail; else elseTemplate">
  <div class="flex flex-col gap-4 pb-6">
    <div *ngIf="!isRefund" class="flex flex-row gap-2 py-2">
      <a
        [routerLink]="'/order'"
        [queryParams]="{}"
        class="bg-white border border-gray-200 rounded-lg p-1 w-fit flex items-center cursor-pointer"
      >
        <i class="sctr-icon-arrow-left text-xl"></i>
      </a>
      <span class="text-xl font-semibold text-gray-900">{{
        translatePrefix + "order-detail" | translate
      }}</span>
    </div>
    <div class="flex flex-col lg:flex-row gap-4 h-full">
      <div class="flex-1">
        <app-order-information
          [isReshipMode]="isReShipMode"
          [order]="orderDetail"
          [isClearTrackingMethod]="isClearTrackingMethod"
          [trackingTypeList]="trackingTypeList"
          [estimatedArrivalDelivery]="estimatedArrivalDelivery"
          (onUpdateOrderInformation)="onUpdateOrderInformation()"
        ></app-order-information>
      </div>
      <div *ngIf="!getTickets?.length" class="flex-1">
        <app-delivery-information
          [isReshipMode]="isReShipMode"
          [order]="orderDetail"
          (onUpdateDeliveryInf)="onUpdateOrderInformation()"
        ></app-delivery-information>
      </div>
    </div>
    <div>
      <app-order-status [order]="orderDetail"></app-order-status>
    </div>
    <div class="flex flex-col lg:flex-row gap-4">
      <div class="flex-1">
        <app-request-status-detail
          [order]="orderDetail"
        ></app-request-status-detail>
      </div>
      <!--  <div class="flex-1">
        <app-order-status-note></app-order-status-note>
      </div> -->
    </div>
    <div class="flex flex-col gap-4">
      <div class="flex-[2]">
        <app-product-list [order]="orderDetail"></app-product-list>
      </div>
      <div class="flex-1">
        <app-payment-information
          [order]="orderDetail"
        ></app-payment-information>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div
    class="flex items-center h-[calc(100vh_-_146px)] justify-center rounded-md border bg-white"
  >
    <p-progressSpinner [style]="{ width: '80px' }"></p-progressSpinner>
  </div>
</ng-template>

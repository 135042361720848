import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { MenuSidebar } from 'src/app/core/constants/navigation.config';

import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { NavigationItem } from 'src/app/core/models/interfaces/navigation';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  providers: [MessageService],
})
export class SidebarComponent implements OnInit {
  dialItems: any[];
  MenuSidebar = MenuSidebar;
  isMenuShow: boolean = true;
  showChildren: boolean[] = [];

  isProduction = environment.state === 'PRODUCTION';

  @Input() sidebarVisible = false;
  @Output() sidebarVisibleChange = new EventEmitter<boolean>();
  protected readonly SoctripIcons = SoctripIcons;

  constructor(private router: Router) {}

  ngOnInit() {
    this.setupSideBar();
    this.showChildren = this.MenuSidebar.map((item) => !!item.children);
  }

  setupSideBar() {
    const toggleItem = {
      icon: 'sctr-icon-chevron-right',
      command: () => {
        this.isMenuShow = !this.isMenuShow;
      },
    };
    this.dialItems = _.cloneDeep(MenuSidebar);
    this.dialItems.unshift(toggleItem);
    this.dialItems.forEach((item) => {
      item.routerLink = [item.path];
      item.icon += ' before:!text-white';
    });
  }

  isActive(path: string, isParent?: boolean) {
    const locationPath = window.location.pathname;
    if (isParent) {
      return locationPath.slice(1) === path;
    } else {
      const index = locationPath.search(path);
      return (
        index > 0 &&
        locationPath[index - 1] === '/' &&
        locationPath.includes(path)
      );
    }
  }

  getMenuIcon() {
    return this.isMenuShow ? 'pi pi-chevron-left' : 'pi pi-chevron-right';
  }

  updateSideBar(value: boolean = this.sidebarVisible) {
    this.sidebarVisible = value;
    this.sidebarVisibleChange.emit(this.sidebarVisible);
  }

  onNavigate(path?: string) {
    if (!path) return;
    this.router.navigateByUrl(path);
    this.updateSideBar(false);
  }

  onClickItemSidebar(item: NavigationItem, index: number) {
    if (item.children) {
      this.showChildren[index] = !this.showChildren[index];
    } else {
      this.onNavigate(item.path);
    }
  }

}
